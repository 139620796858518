.flashsale-coundown-wrap
	display: flex
	align-items: center
	justify-content: center
	padding: r(25px)
	background: color(main)
	color: #ffffff
	.title
		font-weight: 700
		text-transform: uppercase
		font-size: 2.25rem
		margin-right: r(20px)
	.countdown-wrap
		display: flex
		align-items: center
		p
			font-size: 1.5rem
			margin-right: r(15px)
		.flashsale-countdown
			span
				display: inline-flex
				align-items: center
				justify-content: center
				width: 2.375rem
				height: 2.375rem
				background-color: #ffffff
				font-size: 1.25rem
				font-weight: 700
				color: #000000

.flashsale-item-list
	margin-top: r(20px)
	.flashsale-item
		height: 10.563rem
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		.status
			height: 2.063rem
			background: #fff
			display: inline-flex
			align-items: center
			justify-content: center
			font-size: 1.125rem
			font-weight: 700
			width: 11.188rem
			
		.time
			font-weight: 700
			font-size: 3.75rem
			color: #fff
			margin-top: r(10px)
		&.active
			.status
				background-color: #f8ff03

.flashsale-detail-content
	.flashsale-nav
		margin-bottom: r(40px)
		ul
			li
				display: inline-block
				a
					display: flex
					align-items: center
					justify-content: center
					padding: r(10px) 1rem 
					font-size: 1.25rem
					color: color(gray-1)
				&.active
					a
						background-color: #feb92e
						font-weight: 700
