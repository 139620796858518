.promotion-desc 
	color: #828282
	line-height: 1.5
	font-weight: 700

.promotion-list
	margin-top: r(40px)
	.row
		margin: 0 0 r(-40px) r(-40px)
		> *
			padding: 0 0 r(40px) r(40px)

.promotion-item
	&:not(.promotion-item-no-full-image)
		.img
			a
				+img-scale(100%)
	.link
		display: flex
		align-items: center
		justify-content: center
		padding: r(14px) 0
		border: 1px solid #d8d8d8
		background-color: #f7f7f7
		color: #232323
		line-height: 1.13
		transition: 0.3s all
	&.promotion-item-no-full-image
		display: block
		&.promotion-item-wide
			height: r(310px)
			display: flex
			align-items: center
			justify-content: center
			padding: r(30px)

		&.promotion-item-square
			padding-top: 100%
			position: relative
			.img
				display: block
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)

	&:hover
		.link
			background-color: color(main)
			border: 1px solid color(main)
			color: #fff