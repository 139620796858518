.sidenav-wrapper
	ul
		li
			a
				display: flex
				align-items: center
				padding: r(15px) r(25px)
				color: color(gray-1)
			&.active
				a
					border: 1px solid #a8a8aa
					font-weight: 700