.solution-category-list
	.item
		display: block
		position: relative
		.img
			+img-scale(327 / 690 * 100%)
		.text
			position: absolute
			bottom: 0
			left: 0
			right: 0
			padding: r(15px) 0
			text-align: center
			font-size: 1.125rem
			background-color: rgba(#444444 , 0.6)
			color: #fff
	@media screen and ( max-width: 768.98px )
		.item
			.img
				padding-top: 56.25%