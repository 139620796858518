.news-list
	.row
		.col
			flex: 0 0 100%
			@media (min-width: 576px)
				flex: 0 0 50%
				max-width: 50%
			&:nth-child(n+3)
				.news-brief
					font-size: 0.813rem
				.news-big
					.news-title
						font-size: 1.125rem
				@media (min-width: 1024.98px)
					flex: 0 0 33.33333%
					max-width: 33.33333%

.news-list-section,.solution-list-section
	.pages 
		display: none

