.promotion-notify-wrapper
	z-index: 2
	.promotion-notify
		padding: r(11px)
		background-color: color(main)
		color: #fff
		font-size: 1.125rem
		color: color(grey-1)
		text-align: center
		line-height: 1.375

.fancybox-content.inventories-warning
	line-height: 1.375
	padding: r(40px) !important
	.inventories-header
		padding: 0 0 r(20px)
		text-align: center
		border-bottom: 1px solid #ccc
		font-size: r(28px)
		font-weight: 700
		margin-bottom: r(30px)
	.inventories-btn
		margin-top: r(30px)
		justify-content: center
		.btn-cancel
			background: #666
			color: #fff
			margin-left: 15px
