.tracking-order
	padding: r(60px) r(80px)
	color: #6c6c6c
	border: 1px solid #a8a8aa
	line-height: 1.375
	.section-title
		margin-bottom: r(20px)
	p
		margin-bottom: r(20px)
	.form-group
		label
			display: block
			margin-bottom: r(10px)
	.btn
		margin-top: r(30px)
		width: 100%
	@media screen and ( max-width: 768.98px )
		padding: r(30px)

.tracking-progressbar
	display: flex
	margin-bottom: 60px
	.progress-item
		position: relative
		width: 20%
		display: flex
		flex-direction: column
		.text
			text-align: center
			font-size: r(14px)
			line-height: 1.25
			flex: 1 1 0%
			span
				font-weight: 700
			.time
				color: #160d0d
		.bar
			height: 2px
			margin: r(42.5px) 0px 0
			background: #e1e1e1
			position: relative
			.fill-color
				width: 50%
				height: 100%
				background: transparent
		.circle
			position: absolute
			width: r(50px)
			height: r(50px)
			background: #fafafa
			top: r(67.5px)
			left: 50%
			margin-top: -15px
			margin-left: -15px
			border-radius: 50%
			color: #666666
			font-size: 20px
			display: flex
			align-items: center
			justify-content: center
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1)
			z-index: 2
		&.done
			.bar
				.fill-color
					width: 100%
					background: map-get($colors, main)
			.circle
				background: color(main)
				color: #fff
		&.active
			.fill-color
				width: 100%
				background: map-get($colors, main)
			.circle
				background: color(main)
				color: #fff
		&:first-child
			.bar
				left: 50%
				width: 50%
		&:last-child
			.bar
				width: 50%
	@media screen and ( max-width: 1024.98px )
		justify-content: center
		.progress-item
			display: flex
			align-items: center
			width: 20%
			.text
				display: none
			.circle
				position: static
				margin-left: 0
				margin-top: 0
				opacity: 0.6
				font-size: 18px
			.bar
				margin: 0 20px
				flex: 1
				bottom: 50%
				left: 50%
				opacity: 0.6
				width: 50%
			&:last-child
				.bar
					display: none
			&.active,&.done
				.circle
					opacity: 1
				.bar
					opacity: 1

	@media screen and ( max-width: 576px )
		.progress-item
			width: 50%