.dropdown 
	position: relative
	display: inline-block

.dropdown-content 
	display: none
	position: absolute
	min-width: 160px
	right: 0
	z-index: 1
	> *
		background-color: #ffffff
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
		padding: 20px

.dropdown:hover 
	.dropdown-content 
		display: block

