.product-list-section
	.pages
		display: none 

.product-slider
	.swiper-btn
		top: 11.25 / 2 * 1rem

.product-list-desc
	padding-top: 2.5rem
	border-top: 1px solid #a8a8aa