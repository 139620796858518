.form-group
	input[type="text"],textarea,select
		height: 2.5rem
		width: 100%
		border: 1px solid #e0e0e0
		padding: 0 r(15px)
		font-size: 0.938rem
		color: #1e1e1e
	textarea
		padding: r(10px) r(15px)
		height: 9.375rem

.frm-btn
	margin-top: r(20px)
	input
		height: 2.5rem
		background-color: color(main)
		width: 10.625rem
		color: #fff
		border: 0
		font-size: 1rem
		cursor: pointer