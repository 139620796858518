.promotion-banner-slide
	.swiper-pagination
		bottom: r(40px)
		span
			opacity: 1
			width: 1.125rem
			height: 1.125rem
			background-color: #ffffff
			margin: 0 10px !important
			&.swiper-pagination-bullet-active
				background-color: color(main)

.landing-page-banner-slider
	.swiper-pagination
		bottom: r(40px)
		left: 50%
		transform: translateX(-50%)
		span
			opacity: 1
			width: 1.125rem
			height: 1.125rem
			background-color: #ffffff
			margin: 0 5px !important
			&.swiper-pagination-bullet-active
				background-color: color(main)