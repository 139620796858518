.popup-promo, div[id*=popup_],.fancybox-content
	overflow: initial !important
	padding: 0 !important

#popup_2
	width: 930px

.popup-promo-wrapper
	.popup-img
		img
			width: 100%
	.popup-form
		padding: r(30px)
		height: 100%
		h2
			font-size: 1.563rem
			line-height: 1.2
			text-transform: uppercase
			font-weight: 700
			margin-bottom: r(20px)
		.form-group
			+ .form-group
				margin-top: r(20px)
			input[type=text]
				height: 2.125rem
				background-color: #f4f4f4
				padding: r(10px) r(20px)
				font-size: 0.875rem
				border: 0
				&::placeholder
					color: #999999
					font-size: 0.875rem
		.frm-btn
			margin-top: r(20px)
			input
				width: 100%
				height: 2.125rem
				font-size: 0.875rem