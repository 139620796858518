.product-filter-wrap
	display: flex
	flex-wrap: wrap
	margin-left: r(-30px)
	margin-bottom: r(-15px)
	> *
		margin-left: r(30px)
		margin-bottom: r(15px)
	@media screen and ( max-width: 768.98px )
		margin: 0

.product-filter-container
	.product-filter-container-title,.product-filter-container-close, .product-filter-container-btn
		display: none
	@media screen and ( max-width: 768.98px )
		position: fixed
		background: #fff
		top: 0
		left: 0
		width: 100%
		height: 100%
		overflow: auto
		z-index: 1000
		padding: r(30px)
		display: none
		.product-filter-container-title
			display: block
			font-size: r(24px)
			margin-bottom: r(20px)
			font-weight: 700
			color: color(main)
			text-align: center
		.product-filter-container-close
			display: flex
			position: absolute
			width: r(40px)
			height: r(40px)
			align-items: center
			justify-content: center
			background-color: color(main)
			font-size: r(24px)
			right: 0
			top: 0
			color: #fff
		.product-filter-container-btn
			display: flex
			justify-content: center
			a
				display: flex
				align-items: center
				justify-content: center
				height: r(40px)
				padding: 0 r(15px)
				&.btn-filter-comfirm
					color: #fff
					background-color: color(main)
				&.btn-filter-reset
					background-color: #f6f6f6
					border: 1px solid #ddd
					margin-left: r(15px)

.product-filter-item
	position: relative
	flex-basis: 0
	.product-filter-title
		display: inline-flex
		align-items: center
		white-space: nowrap
		cursor: pointer
		.icon
			display: flex
			align-items: center
			justify-content: center
			border: 1px solid #cccccc
			background-color: #ffffff
			border-radius: 50%
			width: 1.875rem
			height: 1.875rem
			margin-left: r(10px)
	.product-filter-body
		position: absolute
		top: 100%
		left: 0
		min-width: 14.813rem
		padding-top: 10px
		display: none
		text-align: left
		z-index: 2
		.title
			display: block
			text-align: center
			margin-bottom: r(15px)
			line-height: 1.25
	.product-filter-group
		background-color: #f7f7f7
		border: 1px solid #d8d8d8
		padding: r(30px)
		ul
			li
				& + li
					margin-top: r(15px)

	.option-link
		display: flex
		align-items: center
		margin-left: r(-10px)
		> *
			margin-left: r(10px)
		.opt-name
			color: #3a3a3a
			line-height: 1.25
		.opt-icon,.color
			height: 1.875rem
			width: 1.875rem
			border-radius: 50%
		.opt-icon
			border: 1px solid #cccccc
			background: #fff
			display: flex
			align-items: center
			justify-content: center
			&:before
				content: ''
				background-image: url(../img/icon/check.png)
				width: 1rem
				height: 1rem
				background-size: contain
				background-repeat: no-repeat
				background-position: center center
				opacity: 0
		&.active
			.opt-icon
				&:before
					opacity: 1
	&.product-sort
		.product-filter-body
			right: 0
			left: auto
	&:hover
		.product-filter-body
			display: block
	@media screen and ( max-width: 768.98px )
		&:not(.product-sort)
			flex: 0 0 100%
			border: 1px solid #d8d8d8
			margin-left: 0
			margin-bottom: r(20px)
			padding: r(20px)
			background-color: #f7f7f7
			.product-filter-title
				justify-content: center
				padding: r(10px)
				font-size: r(18px)
				width: 100%
				padding: 0
				margin-bottom: r(20px)
				font-weight: 700
				.icon
					display: none
			.product-filter-body
				display: block
				position: static
				min-width: auto
				padding-top: 0
			.product-filter-group
				border: 0
				padding: 0
				background-color: transparent
				.title
					display: none
		&.product-sort
			text-align: left
			.product-filter-body
				right: auto
				left: 0

.product-sort-wrap
	.product-filter-toggle
		display: none
	@media screen and ( max-width: 768.98px )
		display: flex
		justify-content: space-between
		.product-filter-toggle
			display: flex
			align-items: center
			font-size: r(18px)
			em
				margin-left: r(15px)

.product-selected-filter
	padding-top: 20px
	display: flex
	flex-wrap: wrap
	margin-bottom: -10px
	align-items: center
	.item
		font-size: 14px
		display: flex
		height: 30px
		margin-bottom: 10px
		align-items: center
		&:not(.default)
			padding: 4px 12px
			background: lighten(color(main), 60%)
			line-height: 20px
			position: relative
			margin-right: 10px
			.remove
				display: flex
				margin-left: 6px
				color: #666
				font-size: 15px
		&.default
			text-decoration: underline
			color: color(main)
	@media screen and ( max-width: 1024.98px )
		padding-top: 0