footer
	padding: r(40px) 0
	background-color: #f7f7f7
	color: #494949
	.footer-title
		font-size: 1.125rem
		font-weight: 700
		line-height: normal
		margin-bottom: r(15px)
	.footer-link
		ul
			li
				a
					line-height: 1.54
					font-size: 0.813rem
	.footer-contact,.footer-registered
		line-height: 1.54
		font-size: 0.813rem
		h4
			font-weight: 700
			margin-top: r(15px)
	.footer-social
		line-height: 1.54
		font-size: 0.813rem
		margin-top: r(20px)
		p 
			font-weight: 500
			margin-bottom: r(10px)
		ul
			li
				display: inline-block
				margin-right: r(5px)
	.footer-registered
		img
			margin-bottom: r(15px)
	.subscribefrm
		display: flex
		input
			height: 2.25rem
			border: 0.063rem solid #d5d5d5
			flex: 1
			padding: 0 12px
			font-size: r(12px)
		button
			height: 2.25rem
			border: 0.063rem solid #d5d5d5
			background-color: #f7f7f7
			font-size: 0.813rem
			border-left: 0
			color: #494949
			width: 6.313rem
	.footer-dealer-toggle
		a
			display: inline-flex
			align-items: center
			.icon
				margin-right: r(10px)
			.text
				font-size: r(13px)
				color: #76777b
				text-align: left

.footer-dealer-list
	padding: r(35px) 0 r(180px)
	background: #fff
	display: none
	.dealer-tab-link
		padding-bottom: 8px
		border-bottom: 1px solid #dedede
		li
			display: inline-block
			margin-right: r(20px)
			a
				font-size: 0.938rem
	.dealer-tab-content
		padding-top: r(40px)
	.dealer-item
		color: #494949
		font-size: 0.813rem
		line-height: 1.2
		.dealer-title
			font-weight: 700
			margin-bottom: r(10px)
