

@mixin color-modifiers($attribute: 'color', $prefix: '-') 
	@each $name, $hex in $colors 
		&#{$prefix}#{$name} 
			#{$attribute}: $hex

@mixin background-modifiers($attribute: 'background', $prefix: '-') 
	@each $name, $hex in $colors 
		&#{$prefix}#{$name} 
			#{$attribute}: $hex
		
.text 
	@include color-modifiers($prefix: '-')

.background 
	@include background-modifiers($prefix: '-')