header
	padding: 0 0 r(20px) 0
	background: #fff
	.header-top-wrap
		display: flex
		justify-content: flex-end
		margin-bottom: r(20px)
		padding-top: r(20px)
		.header-top-item
			display: inline-flex
			+ .header-top-item
				margin-left: r(30px)
			a
				display: inline-flex
				align-items: center
			.icon
				margin-right: r(10px)
			.text
				font-size: r(13px)
				color: #76777b
				text-align: left

	.language
		display: flex
		align-items: flex-end
		height: 100%
		p
			font-size: r(13px)
			color: #76777b
			a
				&.active
					color: color(main)
					font-weight: 700
	.menu
		font-size: 1.125rem
		color: color(grey-1)
		> ul
			display: flex
			> li
				margin-left: r(30px)
				> a
					text-transform: uppercase
					line-height: 1.125
					&:hover
						color: color(main)
					&.zone-1843
						color: #BB2528
				&.active
					> a
						font-weight: 700

	.searchbox
		position: relative
		input
			height: 3.313rem
			border: 0.063rem solid #a8a8aa
			padding: 0 r(60px) 0 r(20px)
			font-size: 0.813rem
			color: #b0b0b0
			width: 31.125rem
		button
			position: absolute
			top: 50%
			right: r(10px)
			transform: translateY(-50%)
			background: transparent
			border: 0

	.search-wrapper
		margin-left: auto

	.account
		.firstnav
			> span
				font-weight: 700
				white-space: nowrap
		a
			font-size: 14px
			white-space: nowrap
			span
				margin: 0 0 0 5px
				width: 90px
				overflow: hidden
				text-overflow: ellipsis
			em
				font-size: 18px
				color: #cccccb
		.dropdown-content
			padding-top: 10px
			right: auto
			left: 50%
			transform: translate(-50%)
			top: 100%
			z-index: 5
			ul
				li
					+ li
						margin-top: 10px

	.cart-wrapper
		margin-left: r(20px)
		width: 2.125rem
		.cart-toggle
			position: relative
			cursor: pointer
			.cart-amount
				position: absolute
				display: flex
				align-items: center
				justify-content: center
				border-radius: 50%
				width: 1.313rem
				height: 1.313rem
				background-color: color(main)
				color: #fff
				top: r(-11px)
				right: r(-11px)
				font-size: 0.875rem
		.cart-dropdown
			position: absolute
			top: 100%
			right: 0
			width: 28.063rem
			background: #fff
			border-radius: 10px
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1)
			z-index: 20
			display: none
			&.show
				display: block

		.cart-popup-amount
			display: flex
			align-items: center
			img
				width: 2.125rem
			.cart-amount
				width: 1.813rem
				height: 1.813rem
				border-radius: 50%
				display: flex
				align-items: center
				justify-content: center
				color: #fff
				font-size: 1.125rem
				font-weight: 500
				background: color(main)
				margin-left: 5px

		.cart-container
			padding: r(25px)

		.cart-heading
			display: flex
			justify-content: space-between
			align-items: center
			padding-bottom: r(12px)
			border-bottom: 3px solid #ebebeb
			margin-bottom: r(15px)

		.cart-body
			overflow-y: auto
			height: r(360px)
			padding-right: 15px
			&::-webkit-scrollbar
				width: 4px
				background-color: #ccc
			&::-webkit-scrollbar-thumb
				background-color: map-get($colors, main)
			ul
				li
					padding-left: r(25px)
					padding-top: r(25px)
					+ li
						margin-top: r(25px)
						border-top: 1px solid #ebebeb
		
		.cart-product
			.img
				display: block
				width: r(150px)
			.caption
				flex: 1
				text-align: left
				padding-left: r(20px)
			.brand
				font-weight: 700
			.name
				display: block
				margin-bottom: 10px
				font-size: 0.813rem
				&:hover
					color: map-get($colors, main)
			.quantity
				color: #999

		.cart-button
			margin-top: r(25px)
			a
				height: r(40px)
				width: 100%




	@media screen and ( min-width: 1024px )
		.menu
			> ul
				> li
					&.has-mega
						.mega-toggle-mobile
							display: none
						&:hover
							.mega-menu
								display: block
	@media screen and ( max-width: 1440.98px )
		.searchbox
			input
				width: 20rem

	@media screen and ( max-width: 1280.98px )
		.searchbox
			input
				width: 15rem

	@media screen and ( max-width: 1024.98px )
		padding: 0 0 10px 0
		.header-bottom-wrap
			flex-wrap: wrap
			padding-top: 10px
			position: relative
		.menu-toggle
			font-size: 24px
			order: 1
		.logo
			order: 2
			position: absolute
			top: 30%
			left: 50%
			transform: translate(-50%,-50%)
			img
				max-height: 32px
		.cart-wrapper
			order: 3
		.search-wrapper
			order: 4
			width: 100%
			margin-top: 10px
		.searchbox
			input
				width: 100%
				height: 36px
			button
				img
					width: 24px
		.menu
			> ul
				> li
					&.has-mega
						display: flex
						justify-content: space-between
						align-items: center
						.mega-toggle-mobile
							font-size: 18px
		.account
			.text
				color: #fff !important
				font-size: 14px !important
.offside
	width: 280px !important

.offside, .offside--left
	left: -280px !important

.offside--left.is-open,.offside-js--is-left .offside-sliding-element 
	transform: translate3d(280px, 0, 0) !important


.menu-mobile-offcanvas
	color: #fff
	@media screen and ( min-width: 1024.98px )
		display: none
	.offcanvas-close
		position: absolute
		right: 10px
		top: 10px
		font-size: r(30px)
	.offcanvas-wrap
		background: color(main)
		padding: r(30px) r(20px)
		position: relative
		height: 100%
	.menu
		ul
			flex-direction: column
			li
				margin-left: 0
				+ li
					margin-top: 20px
					padding-top: 20px
					border-top: 1px solid #fff
				a
					color: #fff
					font-size: 14px
					text-transform: none
	.header-top-wrap
		flex-direction: column
		margin-top: 25px
		padding-top: 25px
		border-top: 8px solid darken(color(main), 5%)
		.header-top-item
			a
				.text
					font-size: 14px
					color: #fff
					line-height: 1.25
					br
						display: none
			.icon
				filter: brightness(0) invert(1)
			+ .header-top-item
				margin-left: 0
				margin-top: 20px
				padding-top: 20px
				border-top: 1px solid #fff
	.language 
		p 
			font-size: 14px
			a.active 
				color: #fff
				text-decoration: underline

.offcanvas-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(#000, 0.3)
	display: none
	z-index: 998

.mega-menu
	position: absolute
	top: 100%
	left: 0
	width: 100%
	padding-top: r(20px)
	display: none
	z-index: 5
	&::before
		position: absolute
		content: ''
		bottom: 100%
		height: 20px
		width: 100%
	.mega-menu-container
		background: #f7f7f7
		border: 1px solid #d8d8d8
		padding: r(50px) r(35px) 0

	.mega-menu-link
		border-right: 1px solid #c1c1c1
		padding-bottom: r(50px)
		ul
			li
				text-align: right
				+ li
					margin-top: r(10px)
				a
					display: block
					padding-right: 12px
					font-size: 0.938rem
					line-height: 1.375
					color: #323232
					position: relative
					&::before
						position: absolute
						content: ''
						top: 50%
						right: 0
						transform: translate(50%, -50%)
						width: 5px
						height: 5px
						border-radius: 50%
						background-color: color(main)
						opacity: 0
						transition: 0.3s all
				&:hover
					a
						color: color(main)
						&::before
							opacity: 1
				&.active
					a
						color: color(main)
						font-weight: 700
						&::before
							opacity: 1


	.mega-menu-catalogue
		display: none

	.catalogue-list
		display: flex
		padding-bottom: r(50px)

	.catalogue-link
		display: block
		text-align: center
		.img
			padding-top: 100%
			position: relative
			margin: 0 auto r(20px)
			border-radius: 50%
			border: 1px solid #cccccc
			overflow: hidden
			transition: 0.3s all
			background: #fff
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
		.text
			line-height: 1.25
			font-size: r(14px)
		&.active
			.img
				border-color: color(main)
			.text
				font-weight: 700
				color: color(main)
		&:hover
			.img
				border-color: color(main)
	@media screen and (min-width: 1024.98px) and ( max-width: 1368.98px )
		.mega-menu-container
			height: calc( 100vh - 190px )
			overflow-y: auto
			&::-webkit-scrollbar
				width: 4px
				background-color: #ccc
			&::-webkit-scrollbar-thumb
				background-color: map-get($colors, main)
	@media screen and ( max-width: 1024.98px )
		position: absolute
		top: 0
		display: block
		left: -100%
		transition: 0.3s all
		height: 100vh
		padding-top: 0
		overflow: hidden
		&::before
			display: none
		&.show
			left: 0
		.mega-close
			position: absolute
			top: 0
			right: 0
			width: 30px
			height: 30px
			background-color: #666
			display: flex
			align-items: center
			justify-content: center
			font-size: 18px
			z-index: 1

		.mega-menu-container
			padding: 40px 0
			overflow-y: auto
			height: 100%
			.mega-menu-link
				border-right: 0
				padding-bottom: 0
				ul
					padding: 0 15px
					li
						text-align: left
						+ li
							padding-top: 15px
							margin-top: 15px
							border-top: 1px solid #ccc
						a
							font-size: 14px
							padding: 0
			.col-lg-7
				display: none

		&.mega-1669
			.col-lg-7
				display: block
			.mega-menu-catalogue
				.catalogue-link 
					text-align: left
					padding: 0 0 0 30px
					.img
						display: none
					.text
						padding: 8px 15px
						border-left: 1px solid #999
						color: #323232
						font-size: 14px

.suggestsearch
	position: absolute
	top: 100%
	padding: 20px
	background: #f9f9f9
	border: 1px solid #eeeeee
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15)
	margin-top: 10px
	z-index: 10
	display: none
	width: 100%
	background: #fff
	.keyword-list
		line-height: 1.375
		ul
			max-height: 20rem
			overflow-y: auto
			padding-right: 10px
			&::-webkit-scrollbar
				width: 5px
				background: #e5e5e5
			&::-webkit-scrollbar-thumb
				background: color(main)
			li
				+ li
					margin-top: 10px
					padding-top: 10px
					border-top: 1px solid #e5e5e5
				a
					font-size: r(15px)
					color: #666

.sticky-wrapper.is-sticky header
	box-shadow: 0 0 15px 0 rgba(0,0,0, 0.05)

.offside
	height: 100vh