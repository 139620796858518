//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: r(20px)
$gl-gutter-vertical: r(20px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 13px
	line-height: 1
	font-family: 'Roboto', sans-serif
	color: #494949
	@media (min-width: 1024px) 
		font-size: 14px
	@media (min-width: 1280px)
		font-size: 16px
body.offside-js--is-open
	overflow-y: hidden
*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

.lazyload
	transition: opacity 500ms ease-in-out
	max-width: 100%
	opacity: 0
	&.lazyloaded 
		opacity: 1
.frm-btnwrap
	.label
		display: none
.frm-btn-reset,#ajax_msg
	display: none !important
label.error,.coupon-apply-error
	font-size: 12px
	color: red
	line-height: 2