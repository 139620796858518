.room-heading-section
	.section-title
		text-transform: none
		margin-bottom: 0
	.article-content
		overflow: auto
		padding-right: 10px
		&::-webkit-scrollbar
			width: 4px
			background: #fafafa
		&::-webkit-scrollbar-thumb
			background: color(main)

.room-collection-section
	.section-title
		text-transform: none
		margin-bottom: r(20px)
		span
			display: block
			font-weight: 400
			font-size: 1rem
	.img
		+img-scale(305 / 458 * 100%)
		&.img-2
			padding-top: 510 / 616 * 100%
		&.img-3
			padding-top: 462 / 790 * 100%

.room-category-section
	.section-title
		text-transform: none
		margin-bottom: r(20px)
	.subnav-category-wrap
		padding: r(45px)
		background-color: #f7f7f7
		border: 1px solid #d8d8d8
		margin-top: 0
	.catalogue-list
		margin: 0 0 r(-40px) r(-40px)
		> *
			padding: 0 0 r(40px) r(40px)
	@media screen and ( max-width: 768.98px )
		.subnav-category-wrap
			padding: r(30px)

.room-new-product-section, .room-inspiration-section
	.section-title
		text-transform: none
		margin-bottom: r(20px)

.room-inspiration-section
	.news-list
		.news-col
			display: none
			&.show
				display: block