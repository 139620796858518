.product-item
	padding: r(20px) r(15px) r(40px)
	line-height: 1.375
	color: #000
	border: 0.063rem solid transparent
	transition: 0.3s all
	position: relative
	display: flex
	flex-direction: column
	.product-img
		display: flex
		height: r(180px)
		align-items: center
		justify-content: center
		position: relative
		img
			max-height: r(180px)
		.out-of-stock
			position: absolute
			top: 50%
			transform: translateY(-50%)
			display: flex
			height: r(60px)
			background: rgba(#fff , 0.8)
			font-weight: 700
			text-transform: uppercase
			align-items: center
			justify-content: center
			font-size: r(18px)
			left: r(-15px)
			right: r(-15px)
			color: #999
	.product-discount
		position: absolute
		width: 2.625rem
		height: 2.625rem
		background-color: color(main)
		border-radius: 50%
		color: #fff
		font-weight: 700
		font-size: 0.813rem
		display: flex
		align-items: center
		justify-content: center
		top: r(15px)
		right: r(15px)
	.product-label
		position: absolute
		top: 0
		left: 0
		height: 100%
		display: flex
		flex-direction: column
		justify-content: space-between
		span
			display: block
			padding: r(2px) r(12px)
			font-size: 0.938rem
			font-weight: 500
			width: max-content
			+ span
				margin-top: 5px
		.label-new
			&:first-child
				background-color: color(main)
				color: #fff
			+ .label-new
				color: color(main)
				padding-top: 0

	.product-category
		font-size: 0.813rem
		text-transform: uppercase
	.product-brand
		font-weight: 700
	.product-top-right,.product-bottom-left
		display: flex
		align-items: center
	.product-bottom-left
		flex-direction: row-reverse
	.product-name
		a
			display: block
			+line(3)
			height: r(51px)
		&:hover
			a
				text-decoration: underline
	.product-name,.product-code
		font-size: 0.813rem
	.product-code
		margin: r(10px) 0
	.product-oldprice
		line-height: 1
		white-space: nowrap
		font-size: 12px
		margin-left: 5px
	.product-price
		font-size: 14px
		font-weight: 700
		color: color(main)
		white-space: nowrap
		del
			display: block
			height: r(19px)
	.product-bottom-wrap
		min-height: r(36px)
	.product-btn
		em
			font-size: 28px
	@media screen and ( max-width: 1024.98px )
		.product-bottom-left
			flex-direction: column-reverse
			align-items: flex-start
		.product-oldprice
			margin-left: 0
	@media screen and ( min-width: 1024.98px )
		.product-btn
			opacity: 0
			transition: 0.3s all
		&:hover
			border: 0.063rem solid #b9b9b9
			.product-btn
				opacity: 1

.product-slider
	.swiper-slide
		height: auto
		.product-item
			height: 100%
