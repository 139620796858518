.news-subnav-section
	.subnav-category-wrap
		margin-top: 0
		.catalogue-link
			.img
				img
					width: auto
					height: auto
					top: 50%
					left: 50%
					transform: translate(-50%,-50%)