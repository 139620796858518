.dealer-section
	.result-wrapper
		padding: r(60px) 0
		border: 1px solid #c2c2c2
		background-color: #eaeaea
	.filter-map
		padding: 0 r(25px)
		.form-group
			+ .form-group
				margin-top: r(20px)
			select
				background: transparent
				border: 0
				border-bottom: 1px solid #a3a3a3
				padding: 0
		.form-submit
			input
				height: 3.063rem
				width: 100%
				background-color: color(main)
				font-size: 0.938rem
				color: #ffffff
				margin-top: r(30px)
				border: 0
				font-weight: 700
	.find_address
		margin-top: r(30px)
		padding-top: r(30px)
		border-top: 1px solid #a3a3a3
		input[type="text"]
			background: transparent
			border: 0
			border-bottom: 1px solid #a3a3a3
			padding: 0
			height: 36px
			width: 100%
		.mrb10,.mrb20
			align-items: center
			margin-bottom: r(10px)
		.get-dir-button
			input
				height: 3.063rem
				background-color: color(main)
				font-size: 0.938rem
				color: #ffffff
				border: 0
				font-weight: 700
	.result
		margin-top: r(40px)
		border: 0
		padding: 0 r(8px) 0 r(25px)
		ul
			padding-right: r(17px)
			overflow: auto
			max-height: r(396px)
			&::-webkit-scrollbar
				width: 4px
				background: #fff
			&::-webkit-scrollbar-thumb
				background: #1ca128
			li
				padding: r(30px)
				color: #494949
				line-height: 1.375
				.name
					font-size: 1.125rem
					font-weight: 700
				p
					font-size: 0.875rem
				&.active
					background-color: #fff
					border-right: 0.313rem solid color(main)

	.map
		#map_canvas
			min-height: 100%
			width: 100%
			display: none !important
		iframe
			min-height: 100%
			height: 100%
			width: 100%