.product-detail-name 
	font-size: 1.563rem
	color: #1b1b1b
	font-weight: 700
	line-height: 1.2
	margin-bottom: r(10px)

.product-detail-code
	color: #4b4b4b
	font-size: 0.875rem
	line-height: 1.375
	margin-right: r(20px)

.product-detail-rating
	.rating-number
		text-decoration: underline
		margin-left: r(5px)

.product-detail-label
	.label-item
		padding: r(8px) r(12px)
		font-size: 0.938rem
		font-weight: 500
		width: 100%
		text-align: center
		background-color: color(main)
		color: #fff
		display: block
		+ .label-item
			margin-top: r(10px)
.rating-star
	width: 67px
	height: 13px
	background-image: url(../img/star-group.png)
	background-repeat: no-repeat
	div
		background-image: url(../img/star-group-on.png)
		background-repeat: no-repeat
		height: 100%

.product-detail-discount-round
	width: 2.625rem
	height: 2.625rem
	background-color: color(main)
	color: #fff
	font-size: 0.813rem
	font-weight: 700
	display: flex
	align-items: center
	justify-content: center
	border-radius: 50%

.product-detail-images
	padding-top: 52%
	position: relative
	margin-left: r(60px)
	margin-right: r(60px)
	.img
		position: relative
		height: 100%
		img
			position: absolute
			max-width: 100%
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			max-height: 100%
	.swiper-container
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
	@media screen and ( max-width: 576px )
		margin-left: 0
		margin-right: 0
		padding-top: 100%

.product-detail-thumbnail
	.img
		+img-scale(100%)

.shop-status-list
	.shop-status
		display: flex
		align-items: center
		+ .shop-status
			margin-top: r(10px)
		.icon
			width: 1.313rem
			height: 1.313rem
			background-color: #1ca128
			position: relative
			overflow: hidden
			margin-right: r(10px)
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
		.text
			color: color(gray-1)
		&.out-of-stock
			.icon
				background: red
				img
					top: 50%
					left: 50%
					transform: translate(-50%,-50%)
	
.shop-checked-btn
	display: flex
	align-items: center
	justify-content: center
	width: r(325px)
	height: r(30px)
	border: 1px solid #d8d8d8
	color: color(gray-1)
	background-color: #f7f7f7

.product-detail-choose-size
	.choose-size 
		width: (325px)
		height: r(30px)
		border: 1px solid #d8d8d8
		color: color(gray-1)
		background-color: #f7f7f7
		font-size: 1rem
		padding: 0 r(15px)

.promotion-program-link
	color: #1ca128
	margin-top: r(10px)
	text-decoration: underline
	text-underline-position: under
	display: block

.product-price-wrap
	.old-price
		font-size: 1.25rem
		color: color(gray-1)
		text-decoration: line-through
	.discount
		display: inline-flex
		align-items: center
		justify-content: center
		width: 3.75rem
		height: 1.563rem
		background-color: color(main)
		color: #fff
		font-size: 0.813rem
		font-weight: 700
		margin-left: r(20px)
	.price
		color: color(main)
		font-size: 2.188rem
		font-weight: 700
		margin-top: r(10px)

.product-amount-adjust
	padding: 10px
	.spin-btn
		background: #fff

.product-purchase-wrap
	display: flex

.product-detail-purchase
	flex: 1
	display: flex
	align-items: center
	justify-content: center
	color: #fff
	font-size: 1.125rem
	font-weight: 700

.product-detail-hotline
	height: 3.313rem
	display: flex
	align-items: center
	justify-content: center
	background-color: #f7f7f7
	border: 1px solid #d8d8d8
	color: #727272
	font-size: 1.125rem
	line-height: 1.67
	font-weight: 700
	a
		color: color(main)

.product-detail-feature
	ul
		li
			display: flex
			align-items: center
			+ li
				margin-top: r(15px)
				padding-top: r(15px)
				border-top: 1px solid #e0e0e0
			.icon
				margin-right: r(25px)
			.text
				font-size: 0.938rem
				color: #4b4b4b
				flex: 1.25 0 0
				font-weight: 700
				line-height: 1.375
			.link
				display: block
				flex: 1
				margin-left: r(20px)
				color: #999999
				font-size: 0.938rem
				&:hover
					color: color(main)

.product-detail-desc-wrap
	margin-top: r(40px)
	.col-lg-4, .col-lg-8
		position: relative

	.product-detail-view-more
		position: absolute
		bottom: 0
		left: 50%
		transform: translate(-50%,50%)
		width: 50%
		font-size: 0.938rem
		color: #4b4b4b
		font-weight: 700
	.product-detail-tab-content
		display: none
		&.show
			display: block

	.product-detail-desc-title
		display: inline-flex
		align-items: center
		justify-content: center
		height: 2.25rem
		border: 1px solid #d8d8d8
		background-color: #f7f7f7
		color: #4b4b4b
		font-weight: 700
		font-size: 0.938rem
		padding: 0 r(25px)
		border-bottom: 0
		cursor: pointer
		&.active
			background-color: color(main)
			color: #fff
			border: 1px solid color(main)

	.desc-content
		overflow: hidden
		padding-bottom: 30px
		&.expanded
			overflow: hidden
			max-height:  none !important

	.product-detail-desc-content
		padding: r(40px) r(40px) r(15px)
		border: 1px solid #d8d8d8
		.desc-content
			min-height: 570px
			max-height: 570px


	.product-detail-spec
		padding-bottom: r(15px)
		position: relative
		border: 1px solid #d8d8d8

		.desc-content
			min-height: 610px
			max-height: 610px

		.product-detail-view-more
			width: 80%
		
		.product-detail-spec-title
			height: 2.25rem
			border-bottom: 1px solid #d8d8d8
			font-size: 0.938rem
			color: #4b4b4b
			display: flex
			align-items: center
			justify-content: center
			font-weight: 700

		.product-detail-spec-content
			line-height: 1.375
			font-size: 0.938rem
			margin-top: 0
			table
				tr
					td
						padding: r(10px) r(15px)
						border-bottom: 1px solid #d8d8d8
						vertical-align: middle
						&:first-child
							font-weight: 700
							background-color: #f7f7f7
							width: 55%
							border-right: 1px solid #d8d8d8
						&[colspan]
							background: #fff
							font-weight: 400
					&:last-child
						td
							border-bottom: 0
	@media screen and ( max-width: 1024.98px )
		margin-top: r(30px)
		.desc-content
			padding-bottom: 0
		.product-detail-spec
			margin-top: r(20px)
			.desc-content
				min-height: auto
				max-height: none

		.product-detail-desc-content 
			padding: r(30px)
			.desc-content
				min-height: auto
				max-height: none

.frequent-product
	.product-item
		.icon-checked
			width: 1.313rem
			height: 1.313rem
			background-color: #fff
			position: relative
			overflow: hidden
			position: absolute
			top: -1px
			right: -1px
			border: 1px solid color(main)
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				opacity: 0
		&.active
			.icon-checked
				background-color: color(main)
				img
					opacity: 1

.frequent-product-purchase
	padding: r(40px) r(30px) 0
	text-align: center
	line-height: 1.375
	.frequent-product-total
		font-size: 0.938rem
	a
		display: inline-flex
		width: 100%
		align-items: center
		justify-content: center
		height: 2.25rem
		font-size: 0.938rem
		font-size: 0.938rem
		span
			margin: 0 2.5px
		+ a
			margin-top: r(15px)
		&.frequent-like-btn
			background-color: #f7f7f7
			border: 1px solid #d8d8d8
			color: #4b4b4b
	@media screen and ( max-width: 1024.98px )
		padding-top: 0

.product-detail-comment
	padding: r(30px)
	border: 1px solid #d8d8d8
	.commentpanel
		width: 60%
	@media screen and ( max-width: 1280px )
		.commentpanel
			width: 100%

.product-detail-view-more
	background-color: #ffffff
	border: 1px solid #d8d8d8
	height: 2.25rem
	display: flex
	align-items: center
	justify-content: center
	@media screen and ( max-width: 1024.98px )
		display: none

.check-availability-wrap
	padding: 0
	width: 950px
	overflow: initial
	.section-title
		background-color: #f7f7f7
		border-bottom: 1px solid #d8d8d8
		padding: r(20px)
		text-align: center
		text-transform: none
		margin-bottom: 0
	.availability-shop-container
		padding: r(30px)
	.shop-item
		display: flex
		flex-wrap: wrap
		padding: r(20px) r(30px)
		line-height: 1.375
		color: #494949
		border: 1px solid #d8d8d8
		+ .shop-item
			margin-top: r(20px)
		.shop-name
			font-size: 0.938rem
			font-weight: 700
		.shop-address
			font-size: 0.813rem
		.shop-link
			color: color(main)
			text-decoration: underline
			text-underline-position: under
			display: inline-block
			font-size: 0.813rem
			margin-top: r(10px)
		.shop-info
			flex: 0 0 60%
		.shop-status
			flex: 0 0 40%
			font-weight: 700
			font-size: 0.938rem
			color: #1ca128
			text-align: right
	@media screen and ( max-width: 768.98px )
		.shop-item
			padding: r(20px)
			.shop-info,.shop-status
				flex: 0 0 100%
			.shop-status
				margin-top: r(15px)
				text-align: left
.product-detail-video-wrap
	ul
		display: flex
		flex-wrap: wrap
		margin-left: -5px
		margin-bottom: -5px
		li
			width: 20%
			padding-left: 5px
			margin-bottom: 5px
			a
				display: block
				+img-scale(66 / 96 * 100%)
				border-radius: 5px
				overflow: hidden
				background-color: #000
				transition: 0.3s all
				border: 1px solid #fff
				&:before
					position: absolute
					content: ''
					top: 0
					left: 0
					width: 100%
					height: 100%
					background-color: color( main)
					transition: 0.3s all
					opacity: 0
				img
					opacity: 0.85 !important
				em
					position: absolute
					top: 50%
					left: 50%
					transform: translate(-50%,-50%)
					z-index: 3
					font-size: r(36px)
					color: #fff
				&:hover
					border: 1px solid color( main)
					&:before
						opacity: 0.9

.product-share
	width: r(142px)
	.toggle 
		display: flex
		align-items: center
		width: 100%
		height: r(40px)
		border: 1px solid #d8d8d8
		padding: 10px 15px
		justify-content: space-between
		color: #63656a
	.dropdown-content 
		width: 100%
		min-width: auto
		z-index: 10
		.share-list
			box-shadow: none
			padding: 15px
			border: 1px solid #d8d8d8
			border-top: 0
			ul
				li
					+ li
						margin-top: 15px
			a
				display: flex
				align-items: center
				img
					margin-right: 8px
				&.disabled
					pointer-events: none
					opacity: 0.4