.support-title
	padding-bottom: r(15px)
	margin-bottom: r(20px) !important
	border-bottom: 1px solid color(main)
	color: #1e1e1e
	text-transform: none !important

.faq-list
	.faq-item
		line-height: 1.375
		+ .faq-item
			margin-top: r(15px)
			padding-top: r(15px)
			border-top: 1px solid #e0e0e0
		.faq-title
			color: #1e1e1e
			cursor: pointer
			display: flex
			align-items: center
			justify-content: space-between
			&:after
				margin-left: r(15px)
				content: url(../img/icon/minus.png)
				display: flex
				align-items: center
			&.opened
				color: color(main)
				font-weight: 700
				&:after
					content: url(../img/icon/plus.png)
		.faq-content
			padding: r(20px) r(15px) 0
			display: none

.faq-form
	margin-top: r(60px)
	padding: r(60px)
	border: 1px solid #e0e0e0
	.section-title
		text-transform: none
	@media screen and ( max-width: 768.98px )
		margin-top: r(40px)
		padding: r(30px)