.modulepager
	margin-top: r(40px)
	ul
		text-align: center
		li
			display: inline-block
			margin-right: r(10px)
			span,a
				display: flex
				align-items: center
				justify-content: center
				width: 2.5rem
				height: 2.5rem
				background-color: #f5f5f5
				font-size: 0.938rem
				color: #1e1e1e
			&.active
				span,a
					background-color: color(main)
					color: #fff
