.room-detail-section
	.product-detail-images
		margin-left: 0
		margin-right: 0

.room-choose-product
	.title
		padding-bottom: 10px
		border-bottom: 1px solid #a2a2a2

.room-other-model
	.img
		+img-scale(600 / 878 * 100%)

.product-content-popup
	width: 56.563rem
	padding: 0
	overflow: initial
	.img
		padding-top: 100%
		position: relative
		img
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
	.content
		padding: r(40px)
		line-height: 1.375
		> *
			margin-top: r(5px)
		.brand
			font-size: 1.5rem
			font-weight: 700
		.price
			font-size: 2.25rem
			color: color(main)
			font-weight: 700
			margin-top: 0
		.buy-wrap
			display: flex
			> *
				margin-right: r(20px)
			.btn
				height: r(35px)
				font-size: 1rem
	.product-detail-desc-wrap
		padding: r(30px)
		.desc-content
			min-height: auto
			max-height: none
	@media screen and ( max-width: 1024.98px )
		.img
			padding: r(40px) r(40px) 0
			text-align: center
			img
				position: static
				transform: none
		.content
			padding-bottom: 0