.swiper-btn
	position: absolute
	top: 50%
	transform: translateY(-50%)
	cursor: pointer
	margin: 0 r(30px)
	z-index: 2
	width: r(21px)
	&.swiper-btn-prev
		right: 100%
	&.swiper-btn-next
		left: 100%
	@media screen and ( max-width: 768.98px )
		// height: 30px
		// width: 30px
		// padding: 8px
		margin: 0
		// background: color(main)
		// display: flex
		// align-items: center
		// justify-content: center
		// img
		// 	filter: brightness(0) invert(1)
		// 	max-height: 100%
		&.swiper-btn-prev
			right: auto
			left: 0
		&.swiper-btn-next
			left: auto
			right: 0

.btn-view-more
	font-size: 0.813rem
	color: #000000
	span
		font-size: 1rem
		margin-left: 5px
	&:hover
		text-decoration: underline

.btn-view-more-border
	display: inline-flex
	align-items: center
	justify-content: center
	width: 9.938rem
	height: r(32px)
	border-radius: r(12px)
	border: 1px solid #b7b7b7
	color: #484848
	font-size: 1.125rem

.btn-view-more-square
	display: inline-flex
	align-items: center
	justify-content: center
	height: r(40px)
	width: 7rem
	border: 1px solid #717171
	font-size: 1.125rem
	font-weight: 700
	color: color(gray-1)

.btn-group
	display: flex

.btn
	display: inline-flex
	align-items: center
	justify-content: center
	height: r(40px)
	padding: 0 r(15px)
	font-size: 1.125rem
	font-weight: 700
	&.background-main
		color: #fff
	&.btn-border
		border: 1px solid #a8a8aa

.spin-input-wrap
	display: flex
	height: 2.188rem
	.spin-btn
		border: 1px solid color(gray)
		height: 100%
		width: 1.938rem
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
	input
		border: 1px solid color(gray)
		width: 2rem
		border-left: 0
		border-right: 0
		text-align: center

.fancybox-close-small
	right: auto !important
	left: 100%
	background: color(main) !important
	opacity: 1 !important
	svg
		path
			fill: #fff !important
	@media screen and ( max-width: 1024.98px )
		right: 0
		left: auto
