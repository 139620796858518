// // RemixIcons


.ri-menu-line:before
	content: "\EF3E"

.ri-close-line:before
	content: "\EB99"

.ri-filter-line:before
	content: "\ED27"

.ri-arrow-left-s-line:before
	content: "\EA64"

.ri-arrow-right-s-line:before
	content: "\EA6E"

.ri-play-circle-line:before
	content: "\F009"

.ri-article-line:before
	content: "\ea7e"

.ri-check-line:before
	content: "\eb7b"

.ri-truck-line:before
	content: "\f231"

.ri-shield-user-line:before
	content: "\f10c"

.ri-shield-check-line:before
	content: "\f100"

