.cart-section

	.cart-title
		color: #63656a
		font-size: 1.125rem
		font-weight: 700
		text-transform: uppercase

	.cart-table
		width: 100%

	.cart-product
		display: flex
		align-items: center
		.img
			display: flex
			align-items: center
			flex: 0 0 r(250px)
			padding-top: r(150px)
			position: relative
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				max-height: 100%
		.content
			font-size: 0.938rem
			line-height: 1.2
			flex: 1
			.brand
				font-weight: 700

	.cart-price
		line-height: 1.375
		white-space: nowrap
		.old-price
			color: #63656a
			font-size: 1.25rem
			text-decoration: line-through
		.price
			font-size: 1.25rem
			font-weight: 700
		.discount
			width: 3.063rem
			height: 1.875rem
			display: flex
			align-items: center
			justify-content: center
			background-color: color(main)
			color: #ffffff
			font-weight: 700
			margin-top: r(5px)
			margin-left: auto
			margin-right: auto

	.cart-footer
		width: 34.688rem
		margin-left: auto

	.cart-total-wrap
		padding: 30px 0
		border: 1px solid #a8a8aa
		border-top: 0
		display: flex
		justify-content: flex-end
		padding-right: r(80px)
		ul
			font-size: 1.125rem
			width: r(270px)
			li
				display: flex
				align-items: center
				justify-content: space-between
				color: #63656a
				font-weight: 700
				+ li
					margin-top: r(24px)

	.cart-button
		margin-top: r(20px)
		margin-left: r(-10px)
		margin-right: r(-10px)
		.btn
			margin: 0 r(10px)
			width: 50%

	@media screen and (min-width: 768.98px)
		.cart-table
			display: table
			border-collapse: collapse
		.cart-row
			display: table-row

		.cart-header
			border: 1px solid #a8a8aa
			.cart-col
				color: #63656a
				font-size: 1.125rem
				font-weight: 700
				padding: 12px 15px
				&:first-child
					padding-left: r(70px)
					width: 45%
				&:nth-child(2)
					width: 17.5%
				&:nth-child(3)
					width: 5%

		.cart-row-item
			border-bottom: 1px solid #a8a8aa
			.cart-col
				padding: 30px 15px
				&:first-child
					padding-left: r(70px)
					width: 45%

		.cart-col
			display: table-cell
			vertical-align: middle
			padding: 12px 0

	@media screen and ( max-width: 1024.98px )
		.cart-header
			.cart-col
				&:first-child
					padding-left: r(15px)

		.cart-row-item
			.cart-col
				&:first-child
					padding-left: r(15px)

	@media screen and ( max-width: 768.98px )
		.cart-header
			display: none
		.cart-row-item
			display: flex
			align-items: center
			flex-wrap: wrap
			padding-bottom: r(30px)
			border-bottom: 1px solid #a8a8aa
			margin-bottom: r(30px)
			.cart-col
				&:first-child
					width: 100%
					padding-left: 0
				&.cart-col-price
					width: 100%
					margin-bottom: r(20px)
					padding-left: 15.625rem
				&.cart-col-total
					margin-left: auto
		.cart-price
			display: flex
			align-items: center
			flex-wrap: wrap
			> *
				margin-right: 10px
		.cart-button
			margin-top: r(30px)
		.cart-footer
			width: auto
			margin-left: auto
		.cart-total-wrap
			border: 0
			padding: 0

	@media screen and ( max-width: 576px )
		.cart-product
			.img
				flex: 0 0 r(120px)
				margin-right: r(20px)
		.cart-row-item
			.cart-col
				&.cart-col-price
					padding-left: r(140px)