.subnav-section
	.subnav-wrap
		padding: r(20px) r(30px) r(40px)
		border: 0.063rem solid #d8d8d8
		background-color: #f7f7f7

.subnav-category-wrap
	margin-top: r(30px)
	.catalogue-list
		margin: 0 0 r(-40px) r(-40px)
		> *
			padding: 0 0 r(40px) r(40px)
	.catalogue-link
		display: block
		text-align: center
		
		.img
			padding-top: 100%
			position: relative
			margin: 0 auto r(15px)
			border-radius: 50%
			border: 1px solid #cccccc
			overflow: hidden
			transition: 0.3s all
			background: #fff
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				// width: 100%
				// height: 100%
				// object-fit: contain
				// object-position: center
		.text
			line-height: 1.25
		&.active
			.img
				border-color: color(main)
			.text
				font-weight: 700
				color: color(main)
		&:hover
			.img
				border-color: color(main)
.breadcrumb-wrap
	.breadcrumb
		display: flex
		overflow: hidden
		li
			font-size: 0.938rem
			line-height: 1.375
			color: #828282
			white-space: nowrap
			text-overflow: ellipsis
			+ li
				margin-left: r(8px)
				&:before
					content: '/'
					margin-right: r(5px)
			a
				&:hover
					text-decoration: underline

.product-detail-page
	.subnav-category-wrap
		display: none
	.subnav-section
		.subnav-wrap
			padding: r(10px) r(30px)