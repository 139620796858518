.news-detail-section
	.news-detail-title,.news-other-title
		font-size: r(30px)
		line-height: 1.375
		color: #1e1e1e
		padding-bottom: r(15px)
		border-bottom: 1px solid #e0e0e0
		font-weight: 700
	.article-content
		padding-top: r(30px)
	.news-other-list
		margin-top: r(30px)
	.news-other-item
		display: flex
		align-items: center
		+ .news-other-item
			padding-top: r(20px)
			margin-top: r(20px)
			border-top: 1px solid #e0e0e0
		.img
			width: 8.125rem
			height: 5.125rem
			img
				width: 100%
				height: 100%
				object-fit: cover
		.content
			flex: 1
			padding-left: r(15px)
			.title
				font-size: 1.125rem
				font-weight: 700
				margin-bottom: r(5px)
				+line(1)
			.brief
				font-size: 0.813rem
				+line(3)
				line-height: 1.375

.solution-other-section
	margin-top: r(40px)