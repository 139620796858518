.news-item
	display: flex
	flex-direction: column
	line-height: 1.375
	.news-img
		a
			+img-scale(285 / 454 * 100%)
	&.news-small
		.news-title
			height: r(48px)
	&.news-big
		.news-title
			height: r(66px)
			font-size: 1.5rem
	.news-brief
		flex: 1 1 0%
	.news-title
		color: color(gray-1)
		font-size: 1.125rem
		margin: r(12px) 0 r(8px)
		font-weight: 700
		// flex: 1 1 0%
		a
			+line(2)
		&:hover
			text-decoration: underline
	@media screen and ( max-width: 576px )
		&.news-big
			.news-img
				a
					padding-top: 285 / 454 * 100%
			.news-title
				font-size: 1.125rem