.complete-info
	line-height: 1.375
	.title
		display: flex
		height: 6.438rem
		background-color: #00bd3f
		color: #fff
		justify-content: center
		align-items: center
		font-size: 1.125rem
		font-weight: 700
		img
			margin-right: 15px
	.content
		padding: r(30px) 0 0
		.btn
			width: 100%
			font-size: 1rem
.complete-img
	img
		height: 100%
		width: 100%
		object-fit: cover
