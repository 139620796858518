.checkout-section
	color: #63656a
	.cart-button
		justify-content: flex-end
		margin-left: -10px
		margin-right: -10px
		> *
			margin: 0 10px
			min-width: 16.75rem
			max-width: 50%
	@media screen and ( max-width: 576px )
		.cart-button
			> *
				min-width: auto
		
	
.checkout-wrap
	border: 1px solid #a8a8aa
	padding: r(30px) r(20px)

.checkout-title
	font-size: 1.125rem
	font-weight: 700
	margin-bottom: r(30px)

.checkout-form
	.form-group
		display: flex
		align-items: center
		flex-wrap: wrap
		+ .form-group
			margin-top: r(30px)
		label
			flex: 1
			font-weight: 700
			line-height: 1.175
			padding-right: 10px
		.form-control
			width: 75%
			input,select,textarea
				border: 1px solid #a8a8aa
				background-color: #f9f9f9
				font-size: 0.875rem
				height: 2.5rem
				padding: 0 r(20px)
				color: #828282
				&::placeholder
					color: #828282
			textarea
				padding-top: 10px
				padding-bottom: 10px
				height: 5rem
		.note
			margin-top: r(10px)
			font-size: 0.875rem
			color: #828282
		&.form-note
			align-items: flex-start
	@media screen and ( max-width: 576px )
		.form-group
			.form-control
				width: 100%
				margin-top: 10px

.checkout-add-address
	margin-top: r(30px)
	font-weight: 700
	a
		color: color(main)
		text-decoration: underline
		text-underline-position: under

.checkout-check
	input[type=checkbox], input[type=radio]
		clip: rect(0,0,0,0)
		position: absolute
		visibility: hidden
		opacity: 0
		width: 1px
		height: 1px
	input[type=checkbox]
		&:checked
			+ .fake-icon
				background-image: url(../img/icon/checkbox-checked.png)
	input[type=radio]
		&:checked
			+ .fake-icon
				background-image: url(../img/icon/radio-checked.png)
	label
		display: flex
		align-items: center
	.fake-icon
		display: block
		margin-right: 14px
		background-repeat: no-repeat
	&.checkout-checkbox
		.fake-icon
			width: 16px
			height: 16px
			background-image: url(../img/icon/checkbox-empty.png)
	&.checkout-radio
		.fake-icon
			width: 22px
			height: 22px
			background-image: url(../img/icon/radio-empty.png)

.checkout-invoice
	margin-top: r(40px)
	.issue-invoice-form
		margin-top: r(30px)
		display: none

.checkout-shipping
	margin-top: r(70px)
	@media screen and ( max-width: 576px )
		margin-top: r(30px)
.checkout-method-item
	padding: r(30px)
	background-color: #f9f9f9
	border: 1px solid #a8a8aa
	.checkout-check
		.content
			flex: 1
			display: flex
			align-items: center
		.text
			font-weight: 700
	+ .checkout-method-item
		margin-top: r(25px)
	@media screen and ( max-width: 576px )
		padding: r(20px)

.checkout-shipping
	.checkout-method-item
		.content
			.img
				margin-left: auto
			.text
				padding-right: 20px

.checkout-payment
	margin-top: r(30px)
	.checkout-method-item
		.content
			.img
				width: r(80px)
			.text
				flex: 1
				padding-left: r(20px)

.checkout-cart-summary-wrap
	.checkout-title
		display: flex
		justify-content: space-between
		align-items: center
	
.checkout-cart-summary
	.cart-item
		display: flex
		padding-bottom: r(50px)
		margin-bottom: r(30px)
		border-bottom: 1px solid #a8a8aa
		.img
			width: r(140px)
		.content
			flex: 1
			padding: 0 15px
			font-size: 0.938rem
			line-height: 1.2
			flex: 1
			.brand
				font-weight: 700
		.price-wrap
			white-space: nowrap
			line-height: 1.25
			.old-price
				color: #63656a
				font-size: 1.25rem
				text-decoration: line-through
			.price
				font-size: 1.25rem
				font-weight: 700
			.discount
				width: 3.063rem
				height: 1.875rem
				display: flex
				align-items: center
				justify-content: center
				background-color: color(main)
				color: #ffffff
				font-weight: 700
				margin-top: r(5px)
	.cart-summary-total
		li
			display: flex
			justify-content: space-between
			font-weight: 700
			font-size: 1.125rem
			+ li
				margin-top: r(25px)

.cart-coupon-input
	label
		display: block
		margin-bottom: 0.75rem
	.form-inline
		display: flex
		input
			height: r(40px)
			border: 1px solid#a8a8aa
			flex: 1
			background-color: #f9f9f9
		button
			width: r(110px)
			height: r(40px)
			background-color: color(main)
			color: #fff
			border: 0
			text-transform: uppercase
			font-weight: 700
			font-size: 1rem

.cart-coupon-note
	* + *
		margin-top: r(15px)

