.deal-progress-bar
	height: 7px
	background-color: #8b8b8b
	overflow: hidden
	.deal-progress-bar-inner
		background: color(main)
		transition: 0.3s all
		height: 100%

.deal-progress-info
	font-size: 0.813rem
